<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">
      <span>المصاريف</span>
      <v-spacer></v-spacer>

      <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-2xl font-weight-semibold text--primary mb-2">
          {{ numberWithComma(expenses.statistic.allMoney) + expenses.statistic.currencySymbol }}
        </p>
        <span class="text-base">الاكثر صرفا</span>
      </div>

      <div class="chart-wrapper">
        <vue-apex-charts :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>

    <v-card-text class="mt-2">
      <div v-for="(data, index) in expenses.lastFive" :key="index"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3' : ''}`">
        <span :class="`badge-sm ${lastFiveColor[index]}`"></span>
        <span class="text--primary ms-2">{{ data.expenses }}</span>

        <v-spacer></v-spacer>

        <span class="font-weight-semibold text--primary me-6">{{ numberWithComma(data.money) }}</span>
        <!--<span class="font-weight-semibold text--primary me-1">{{ data.percentage.slice(1) }}</span>
        <v-icon size="20" :color="data.percentage.charAt(0) === '+' ? 'success' : 'error'">
          {{ data.percentage.charAt(0) === '+' ? icons.mdiChevronUp : icons.mdiChevronDown }}
        </v-icon> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import numberWithComma from '@/constant/number';
import { getVuetify } from '@core/utils';
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical } from '@mdi/js';
import VueApexCharts from 'vue-apexcharts';

export default {
  props: {
    expenses: {
      type: Object, default: {
        statistic: {
          currencySymbol: " IQD",
          allMoney: 0,
          allMoneyByDay: 0,
          allMoneyByMonth: 0
        },
        lastFive: []
      }
    },

    loading: {
      type: Boolean, default: false,
    },
  },
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify()



    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      colors: [$vuetify.theme.currentTheme.primary],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          startingShape: 'rounded',
          endingShape: 'rounded',
          borderRadius: 4,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    }


    const lastFiveColor = ['success', 'primary', 'warning', 'accent', 'error']


    return {
      chartOptions,
      lastFiveColor,
      numberWithComma,
      series: [
        {
          name: '2020',
          data: [50, 40, 110, 80, 40, 60, 40],
        },
      ],
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}

.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);

  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
